import React from 'react'
import { graphql, Link } from 'gatsby'
import {get, chunk} from 'lodash'
import Helmet from 'react-helmet'
import Layout from '../components/theme/layout'
import Carousel from 'react-bootstrap/Carousel'
import SEO from '../components/seo'
// import RelatedPosts from '../components/related-posts/related-posts'
import styles from '../scss/home.scss'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import { collectionUrl, blogUrl, productCategoryUrl, buildIntersectionObserver } from "../helpers.js"
import { AiOutlineRight } from "react-icons/ai";

import loadable from '@loadable/component'
const ReviewStars = loadable(() => (import('../components/review-stars/review-stars')))
const RelatedProducts = loadable(() => (import('../components/related-products/related-products')))

class RootIndex extends React.Component {
  constructor(props) {
    super(props)
    // This will set the properties we want to render 'Products With Care Section'.
    // Basically, we don't want to render 'H2' from the articles here.
    this.products = []
    this.reviews = get(this, 'props.data.homepageConfig.reviews', [])
    // this.popularPosts = get(this, 'props.data.homepageConfig.popularPosts', [])
    this.reviewsChunked4 = chunk(this.reviews, 4)
    this.siteUrl = get(this, 'props.data.site.siteMetadata.siteUrl')
    this.siteUrlEs = get(this, 'props.data.site.siteMetadata.siteUrlEs')

    this.hreflangUrls = [
      { lang: 'x-default', href: this.siteUrl },
      { lang: 'en', href: this.siteUrl },
      { lang: 'es', href: this.siteUrlEs }
    ]
  }
  
  componentDidMount() {
    if ('IntersectionObserver' in window) {
      // Lazy load divs containing images as background
      this.observer = buildIntersectionObserver(document.querySelectorAll('[data-lazy-class]'), (target, element) => {
        const className = target.getAttribute('data-lazy-class')
        if (!element || element.isIntersecting && target.className.indexOf(className) < 0) {
          target.className = `${target.className} ${className}`
        }
      }, {
        threshold: 1.0
      })
    }    
  }
  
  componentWillUnmount() {
    if (IntersectionObserver in window) {
      this.observer.disconnect();
    }
  }
  
  render() {
    return (
      <Layout containerClassName="home" location={ this.props.location } showTopNotificationBanner="true">
        <SEO>
          <link rel="canonical" href='https://www.honestpaws.com/' key='canonical-url'  />
          { this.hreflangUrls.map(({ lang, href }) => (<link rel="alternate" key={ lang } href={ href } hreflang={ lang } />)) }
        </SEO>
        {/* Section Above the Fold */}
        {/*<section className="above-the-fold bg-yellow-light">*/}
          {/*<div className="container">*/}
            {/*<div className="row d-lg-flex align-items-center">*/}
              {/*<div className="col-lg-6 text-lg-center d-none d-lg-block mt-3">*/}
                {/*<img*/}
                  {/*src="/img/home/Homepage-Image1x.png"*/}
                  {/*srcSet="/img/home/Homepage-Image1x.png 1x, /img/home/Homepage-Image2x-.png 2x"*/}
                  {/*// Only desktop imagen will be set to lazy because we want to make sure it doesn't render on mobile (performance wise)*/}
                  {/*loading="lazy"*/}
                  {/*alt="CBD Oil for dogs and cats"*/}
                  {/*className="main-image img-fluid" />*/}
              {/*</div>*/}
              {/*<div className="col-sm-10 offset-sm-1 offset-lg-0 col-lg-6 text-center text-lg-left">*/}
                {/*<h1 className="mt-md-4 mb-lg-1 text-primary">CBD Bites & Oils Your <br />Pet Will Thank You For</h1>*/}
                {/*<img*/}
                  {/*src="/img/home/Homepage-mobile-image1x.png"*/}
                  {/*srcSet="/img/home/Homepage-mobile-image1x.png 1x, /img/home/Homepage-Image2x-.png 2x"*/}
                  {/*alt="CBD Oil for dogs and cats"*/}
                  {/*className="d-lg-none mt-2 mb-2 img-fluid main-image w-100" />*/}
                {/*<div className="h2">Our products are 100% all-natural pieces <br className="d-none d-lg-block"/> of perfection.</div>*/}
                {/*<div className="btn-container mt-4 d-none d-sm-block">*/}
                  {/*<Link to={ productCategoryUrl('cbd-for-dogs') }*/}
                        {/*className="btn btn-success mr-sm-2 mr-xl-3">Shop For My Dog</Link>*/}
                  {/*<Link to={ productCategoryUrl('cbd-for-cats') }*/}
                        {/*className="btn btn-success">Shop For My Cat</Link>*/}
                {/*</div>*/}
                {/*<div className="btn-container mt-2 d-sm-none">*/}
                  {/*<Link to={ productCategoryUrl('cbd-for-dogs') }*/}
                        {/*className="btn btn-success btn-large">Shop For My Dog</Link>*/}
                  {/*<br className="d-sm-none" />*/}
                  {/*<Link to={ productCategoryUrl('cbd-for-cats') }*/}
                        {/*className="btn btn-success btn-large mt-3">Shop For My Cat</Link>*/}
                {/*</div>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}
        {/*</section>*/}

        <section className="above-the-fold bg-custom-gray">
          <div className="container">
            <div className="row d-lg-flex align-items-center">
              <div className="col-lg-6 text-lg-center d-none d-lg-block mt-3">
                <img
                  src="/img/home/Summer-Bundles-1x.png"
                  srcSet="/img/home/Summer-Bundles-1x.png 1x, /img/home/Summer-Bundles-2x.png 2x"
                  // Only desktop imagen will be set to lazy because we want to make sure it doesn't render on mobile (performance wise)
                  loading="lazy"
                  alt="CBD Oil for dogs and cats"
                  className="main-image img-fluid" />
              </div>
              <div className="col-sm-10 offset-sm-1 offset-lg-0 col-lg-6 text-center text-lg-left">
                <h1 className="mt-md-4 mb-lg-1 text-primary">Limited Time Summer <br />Bundles Are Here</h1>
                <img
                  src="/img/home/Summer-Bundles-1x.png"
                  srcSet="/img/home/Summer-Bundles-1x.png 1x, /img/home/Summer-Bundles-2x.png 2x"
                  alt="CBD Oil for dogs and cats"
                  className="d-lg-none mt-2 mb-2 img-fluid main-image w-100" />
                <div className="h2">Save 10% When You Bundle and <br className="d-none d-lg-block"/> get a Relief Balm for Free!</div>
                <div className="btn-container mt-4 d-none d-sm-block">
                  <Link to='collections/seasonal-bundles/'
                        className="btn btn-primary mr-sm-2 mr-xl-3">Shop the Sale</Link>
                </div>
                <div className="btn-container mt-2 d-sm-none">
                  <Link to='collections/seasonal-bundles/'
                        className="btn btn-primary btn-large">Shop the Sale</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Section Above the Fold  */}
        {/* Section Better Life */}
        <section className="better-life bg-white text-primary text-center">
          <div className="container">
            <div className="link-hc-home-page mb-2 font-weight-bold">
              Best <Link to={collectionUrl('cbd-for-dogs')}>“CBD for Dogs”</Link> “As Voted By <a href="https://www.healthcanal.com/best-cbd-oil/dogs" rel="noreferrer" target="_blank">HealthCanal.com</a>”
            </div>
            <h2 className="mt-3">Shop By Concern</h2>
            <div className="col-12 col-lg-10 mx-auto">
              <div className="row justify-content-center d-none d-md-flex">
                <Link to={collectionUrl('calm')} className="concern-container d-flex flex-column" style={{backgroundColor: '#5EB3E0', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'}} title="Go To Calm Collection">
                  <img src="/img/home/calm-svg-icon.svg" alt="Calm Icon" className="mb-auto mx-auto"/>
                  <div className="collection-info" style={{backgroundColor: '#3697CB'}}>
                    <AiOutlineRight />
                    <p className="title">Calm</p>
                    <p className="subtitle">Promotes relaxation <br/> in your pet</p>
                  </div>
                </Link>

                <Link to={collectionUrl('mobility')} className="concern-container d-flex flex-column" style={{backgroundColor: '#EC5445', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'}} title="Go To Mobility Collection">
                  <img src="/img/home/mobility-svg-icon.svg" alt="Mobility Icon" className="mb-auto mx-auto"/>
                  <div className="collection-info" style={{backgroundColor: '#CC3425'}}>
                    <AiOutlineRight />
                    <p className="title">Mobility</p>
                    <p className="subtitle">Supports healthy <br/>bones and joints</p>
                  </div>
                </Link>

                <Link to={collectionUrl('relief')} className="concern-container d-flex flex-column" style={{backgroundColor: '#C16BC9', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'}} title="Go To Relief Collection">
                  <img src="/img/home/relief-svg-icon.svg" alt="Relief Icon" className="mb-auto mx-auto"/>
                  <div className="collection-info" style={{backgroundColor: '#992FA3'}}>
                    <AiOutlineRight />
                    <p className="title">Relief</p>
                    <p className="subtitle">Relieves bodily <br/>discomfort</p>
                  </div>
                </Link>
              </div>

              <div className="row justify-content-center d-none d-md-flex">
                <Link to={collectionUrl('well')} className="concern-container d-flex flex-column" style={{backgroundColor: '#69CC84', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'}} title="Go To Well Collection">
                  <img src="/img/home/well-svg-icon.svg" alt="Well Icon" className="mb-auto mx-auto"/>
                  <div className="collection-info" style={{backgroundColor: '#30A750'}}>
                    <AiOutlineRight />
                    <p className="title">Well</p>
                    <p className="subtitle">Best for boosting <br/>overall wellness</p>
                  </div>
                </Link>

                <Link to={collectionUrl('immunity')} className="concern-container d-flex flex-column" style={{backgroundColor: '#F77619', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'}} title="Go To Immunity Collection">
                  <img src="/img/home/immunity-svg-icon.svg" alt="Immunity Icon" className="mb-auto mx-auto"/>
                  <div className="collection-info" style={{backgroundColor: '#D65900'}}>
                    <AiOutlineRight />
                    <p className="title">Immunity</p>
                    <p className="subtitle">Enhances the immune <br/>response</p>
                  </div>
                </Link>

                <Link to={collectionUrl('skin-coat')} className="concern-container d-flex flex-column" style={{backgroundColor: '#ADADAD', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'}} title="Go To Skin and Coat Collection">
                  <img src="/img/home/skin-coat-svg-icon.svg" alt="Skin and Coat Icon" className="mb-auto mx-auto"/>
                  <div className="collection-info" style={{backgroundColor: '#909090'}}>
                    <AiOutlineRight />
                    <p className="title">Skin & Coat</p>
                    <p className="subtitle">Help keep skin & coat <br/>healthy and hydrated</p>
                  </div>
                </Link>
              </div>

              <div className="row d-md-none d-flex">
                <Link to={collectionUrl('calm')} className="concern-container" style={{backgroundColor: '#5EB3E0', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'}} title="Go To Calm Collection">
                  <div className="d-flex align-items-center justify-content-center col-3">
                    <img src="/img/home/calm-svg-icon.svg" alt="Calm Icon"/>
                  </div>
                  <div className="collection-info col-9" style={{backgroundColor: '#3697CB'}}>
                    <AiOutlineRight />
                    <p className="title">Calm</p>
                    <p className="subtitle">Promotes relaxation <br/> in your pet</p>
                  </div>
                </Link>

                <Link to={collectionUrl('mobility')} className="concern-container" style={{backgroundColor: '#EC5445', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'}} title="Go To Mobility Collection">
                  <div className="d-flex align-items-center justify-content-center col-3">
                    <img src="/img/home/mobility-svg-icon.svg" alt="Mobility Icon"/>
                  </div>
                  <div className="collection-info col-9" style={{backgroundColor: '#CC3425'}}>
                    <AiOutlineRight />
                    <p className="title">Mobility</p>
                    <p className="subtitle">Supports healthy <br/>bones and joints</p>
                  </div>
                </Link>

                <Link to={collectionUrl('relief')} className="concern-container" style={{backgroundColor: '#C16BC9', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'}} title="Go To Relief Collection">
                  <div className="d-flex align-items-center justify-content-center col-3">
                    <img src="/img/home/relief-svg-icon.svg" alt="Relief Icon"/>
                  </div>
                  <div className="collection-info col-9" style={{backgroundColor: '#992FA3'}}>
                    <AiOutlineRight />
                    <p className="title">Relief</p>
                    <p className="subtitle">Relieves bodily <br/>discomfort</p>
                  </div>
                </Link>

                <Link to={collectionUrl('well')} className="concern-container" style={{backgroundColor: '#69CC84', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'}} title="Go To Well Collection">
                  <div className="d-flex align-items-center justify-content-center col-3">
                    <img src="/img/home/well-svg-icon.svg" alt="Well Icon"/>
                  </div>
                  <div className="collection-info col-9" style={{backgroundColor: '#30A750'}}>
                    <AiOutlineRight />
                    <p className="title">Well</p>
                    <p className="subtitle">Best for boosting <br/>overall wellness</p>
                  </div>
                </Link>

                <Link to={collectionUrl('immunity')} className="concern-container" style={{backgroundColor: '#F77619', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'}} title="Go To Immunity Collection">
                  <div className="d-flex align-items-center justify-content-center col-3">
                    <img src="/img/home/immunity-svg-icon.svg" alt="Immunity Icon"/>
                  </div>
                  <div className="collection-info col-9" style={{backgroundColor: '#D65900'}}>
                    <AiOutlineRight />
                    <p className="title">Immunity</p>
                    <p className="subtitle">Enhances the immune <br/>response</p>
                  </div>
                </Link>

                <Link to={collectionUrl('skin-coat')} className="concern-container" style={{backgroundColor: '#ADADAD', boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'}} title="Go To Skin and Coat Collection">
                  <div className="d-flex align-items-center justify-content-center col-3">
                    <img src="/img/home/skin-coat-svg-icon.svg" alt="Skin and Coat Icon"/>
                  </div>
                  <div className="collection-info col-9" style={{backgroundColor: '#909090'}}>
                    <AiOutlineRight />
                    <p className="title">Skin & Coat</p>
                    <p className="subtitle">Help keep skin & coat <br/>healthy and hydrated</p>
                  </div>
                </Link>
              </div>

            </div>
          </div>
        </section>
        {/* End Section Better Life */}
        {/* Section Our Products */}
        <RelatedProducts className='our-products text-center text-dark bg-yellow-light'
            products={this.products.map(({node}) => node)} readMoreLink={true} title={`Discover Our Popular <br class='d-md-none' />Bites & Oils`}  useBlockButton={false}  />
        {/* End Section Our Products */}
        <div className="container-fluid">
          <div className="row">
            {/* Section CBD Healthy */}
            <section className="text-center col-xs-12 col-md-6 text-dark" data-lazy-class="cbd-healty">
              <div className="col-xs-12 col-md-10 col-xl-8 offset-md-1 offset-xl-2">
                <h2 className="text-primary">Why CBD Is A Healthy <br /> Choice For Your Pet</h2>     
                <p className="col-8 offset-2 my-3 mt-sm-4 mb-sm-5">Discover why CBD can help your best friend feel better.</p>
                <Link to={collectionUrl('cbd-for-dogs')} className="btn btn-success">Read More About CBD</Link>
              </div>   
            </section>
            {/* End Section CBD Healthy */}
            
            {/* Section Products Care */}
            <section className="products-care text-center col-12 col-md-6 text-dark bg-natural">
              <div className="col-12 col-md-10 col-xl-8 offset-md-1 offset-xl-2">
                <h2 className="text-primary">We Make Our Products <br /> With Care</h2>
                <Carousel className="mt-3" controls={false} interval={3000}>
                   <Carousel.Item className="product_care-slide"> 
                     <img
                       className="product_care-image mb-2 img-fluid lazyload"
                       data-src="/img/home/slides/non_gmo.png"
                       alt="non-gmo"
                       loading="lazy" />
                     <Carousel.Caption>
                       <h3>Non GMO</h3>
                       <div className="pl-5 pr-5 product_care-description"></div>
                       <Link to='/products-with-care/' className="btn btn-success mt-3">Learn About Our Products</Link>
                     </Carousel.Caption>
                   </Carousel.Item>
                   <Carousel.Item className="product_care-slide"> 
                     <img
                       className="product_care-image mb-2 img-fluid lazyload"
                       data-src="/img/home/slides/made_in_usa.png"
                       alt="made-in-usa"
                       loading="lazy" />
                     <Carousel.Caption>
                       <h3>Made In USA</h3>
                       <div className="pl-5 pr-5 product_care-description">Our products are lovingly produced right here In the United States for quality assurance.</div>
                       <Link to='/products-with-care/' className="btn btn-success mt-3">Learn About Our Products</Link>
                     </Carousel.Caption>
                   </Carousel.Item>
                   <Carousel.Item className="product_care-slide"> 
                     <img
                       className="product_care-image mb-2 img-fluid lazyload"
                       data-src="/img/home/slides/organic.png"
                       alt="organic"
                       loading="lazy" />
                     <Carousel.Caption>
                       <h3>Organic</h3>
                       <div className="pl-5 pr-5 product_care-description">When it comes to your pet, we think the full-spectrum hemp oil shouldn’t include conventional pesticides.</div>
                       <Link to='/products-with-care/' className="btn btn-success mt-3">Learn About Our Products</Link>
                     </Carousel.Caption>
                   </Carousel.Item>
                   <Carousel.Item className="product_care-slide"> 
                     <img
                       className="product_care-image mb-2 img-fluid lazyload"
                       data-src="/img/home/slides/soy_free.png"
                       alt="soy-free"
                       loading="lazy" />
                     <Carousel.Caption>
                       <h3>Soy Free</h3>
                       <div className="pl-5 pr-5 product_care-description">As a genetically modified food, we think it’s best to leave this one out of our products. Smart, right?</div>
                       <Link to='/products-with-care/' className="btn btn-success mt-3">Learn About Our Products</Link>
                     </Carousel.Caption>
                   </Carousel.Item>
                   <Carousel.Item className="product_care-slide"> 
                     <img
                       className="product_care-image mb-2 img-fluid lazyload"
                       data-src="/img/home/slides/human_grade.png"
                       alt="human-grade"
                       loading="lazy" />
                     <Carousel.Caption>
                       <h3>Human Grade</h3>
                       <div className="pl-5 pr-5 product_care-description">This product is sourced, produced and shipped all under human-grade regulations.</div>
                       <Link to='/products-with-care/' className="btn btn-success mt-3">Learn About Our Products</Link>
                     </Carousel.Caption>
                   </Carousel.Item>
                   <Carousel.Item className="product_care-slide"> 
                     <img
                       className="product_care-image mb-2 img-fluid lazyload"
                       data-src="/img/home/slides/corn_free.png"
                       alt="corn-free"
                       loading="lazy" />
                     <Carousel.Caption>
                       <h3>Corn Free</h3>
                       <div className="pl-5 pr-5 product_care-description">Not to sound corny, but we love your pet so therefore, no corn.</div>
                       <Link to='/products-with-care/' className="btn btn-success mt-3">Learn About Our Products</Link>
                     </Carousel.Caption>
                   </Carousel.Item>
                   <Carousel.Item className="product_care-slide"> 
                     <img
                       className="product_care-image mb-2 img-fluid lazyload"
                       data-src="/img/home/slides/dairy_free.png"
                       alt="dairy-free"
                       loading="lazy" />
                     <Carousel.Caption>
                       <h3>Dairy Free</h3>
                       <div className="pl-5 pr-5 product_care-description">Out of consideration for those dogs allergic to dairy, we’ve left out dairy from our ingredients as well.</div>
                       <Link to='/products-with-care/' className="btn btn-success mt-3">Learn About Our Products</Link>
                     </Carousel.Caption>
                   </Carousel.Item>
                   <Carousel.Item className="product_care-slide"> 
                     <img
                       className="product_care-image mb-2 img-fluid lazyload"
                       data-src="/img/home/slides/gluten_free.png"
                       alt="gluten-free"
                       loading="lazy" />
                     <Carousel.Caption>
                       <h3>Gluten Free</h3>
                       <div className="pl-5 pr-5 product_care-description">There are many reasons for leaving out grain from our ingredients, such as grain allergy considerations.</div>
                       <Link to='/products-with-care/' className="btn btn-success mt-3">Learn About Our Products</Link>
                     </Carousel.Caption>
                   </Carousel.Item>
                   <Carousel.Item className="product_care-slide"> 
                     <img
                       className="product_care-image mb-2 img-fluid lazyload"
                       data-src="/img/home/slides/xylitol_free.png"
                       alt="xylitol-free"
                       loading="lazy" />
                     <Carousel.Caption>
                       <h3>Xylitol Free</h3>
                       <div className="pl-5 pr-5 product_care-description">Xylitol is a toxic ingredient to dogs, so obviously we absolutely leave it out.</div>
                       <Link to='/products-with-care/' className="btn btn-success mt-3">Learn About Our Products</Link>
                     </Carousel.Caption>
                   </Carousel.Item>
                   <Carousel.Item className="product_care-slide"> 
                     <img
                       className="product_care-image mb-2 img-fluid lazyload"
                       data-src="/img/home/slides/vegan.png"
                       alt="vegan"
                       loading="lazy" />
                     <Carousel.Caption>
                       <h3>Vegan</h3>
                       <div className="pl-5 pr-5 product_care-description">All of our tinctures do not include any animal product ingredients.</div>
                       <Link to='/products-with-care/' className="btn btn-success mt-3">Learn About Our Products</Link>
                     </Carousel.Caption>
                   </Carousel.Item>
                </Carousel> 
              </div>    
            </section>
            {/* End Section Products Care */}
          </div>
        </div>
        {/* Section Petimonials */}
        <section className="petimonials bg-light text-dark text-center">
          <div className="container-fluid">
            <h2>Petimonials</h2> 
            <p className="my-3 mt-sm-4 mb-sm-5 px-2">We love to share how we are improving the lives of your pets.</p>
            {/* This carousel will show only for ipad and desktop  */}
            <Carousel className="carousel-reviews container-fluid text-left d-none d-md-block" controls={false} interval={20000}>
              { this.reviewsChunked4.map((reviews, index) => {
                return (
                  <Carousel.Item className="reviews-slide" key={index}>
                    <div className="row">
                      {
                        reviews.map((review, index) => {
                          let containerClasses = 'col-md-4 col-lg-3'
                          if (index > 2) {
                            containerClasses += ' d-none d-lg-block'
                          }
                          
                          return (
                            <div className={ containerClasses } key={index}>
                              <div className="card">
                                 {/* <Img className="card-img-top lazyload" fluid={review.picture.fluid} alt={review.name} /> */}
                                 <img className="card-img-top lazyload" 
                                    data-src={review.picture.fluid.src}
                                    data-srcset={review.picture.fluid.srcSet} 
                                    data-sizes={review.picture.fluid.sizes} 
                                    alt={review.name}
                                    loading="lazy" />
                                 <div className="card-body">
                                   <h3 className="card-title mb-0">{ review.name }</h3>
                                   <div className="card-subtitle">{ review.breed }</div>
                                   <ReviewStars stars={review.stars} />
                                   <p className="card-text mt-4">
                                     <q>{ review.review.review }</q>
                                   </p>
                                 </div>
                              </div>
                            </div>
                          )
                        })
                      } 
                    </div>
                  </Carousel.Item>
                )
              })}
            </Carousel>
            
            {/* This carousel will show only for mobile  */}
            <Carousel className="carousel-reviews container-fluid text-left d-md-none" controls={false}>
              { this.reviews.map((review, index) => {
                return (
                  <Carousel.Item className="reviews-slide" key={index}>
                    <div className="row">
                      <div className='col-12'>
                        <div className="card">
                          <img className="card-img-top lazyload" 
                            data-src={review.picture.fluid.src}
                            data-srcset={review.picture.fluid.srcSet} 
                            data-sizes={review.picture.fluid.sizes} 
                            alt={review.name} 
                            loading="lazy" />
                          <div className="card-body">
                            <h3 className="card-title mb-0">{ review.name }</h3>
                            <div className="card-subtitle">{ review.breed }</div>
                            <ReviewStars stars={review.stars} />
                            <p className="card-text mt-4">
                             <q>{ review.review.review }</q>
                            </p>
                           </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                )
              })}
            </Carousel>
            <Link to="/reviews/" className="btn btn-primary">Read All Reviews</Link>
          </div>
        </section>
        {/* End Section Petimonials */}

        {/* Section Popular Posts */}
        {/*<section className="text-dark text-center bg-light popular-posts-section">*/}
          {/*<div className="container">*/}
            {/*<div className="row">*/}
              {/*<RelatedPosts relatedPosts={this.popularPosts} containerClassName="col-12 col-lg-8 mx-auto" title="Popular Posts" />*/}
            {/*</div>*/}
          {/*</div>*/}
        {/*</section>*/}
        {/* End Section Popular Posts */}

        {/* Section Money Back Guanrantee */}
        <section className=" text-dark text-center text-md-right" data-lazy-class="money-guarantee">
          <div className="container">
            <h2 className="text-center text-md-right m-0">30-Day Money Back <br className="d-md-none"/>Guarantee!</h2>
            <div className="row">
              <div className="col-12 col-lg-6 offset-lg-6">
                <p className="col-xs-12 pl-0 pr-0 col-lg-10 offset-lg-2 my-3 mt-md-3 mb-md-3">
                  We hold ourselves to the highest of standards when it comes to your satisfaction. If you are not satisfied with your product, we do have a 30 day money back guarantee! No if’s, no but’s, no disappointed pups.
                </p>
                <Link to={ productCategoryUrl() } className="btn btn-success">Shop Now</Link>
              </div>
            </div>
          </div>
        </section>
        {/* End Section Money Back Guanrantee */}
        {/* Section As Seen On */}
        <section className="as-seen-on bg-natural text-center">
          <h2 className="text-primary">As Seen On</h2>
          <div className="container">
            <div className="logos d-flex flex-wrap justify-content-center align-items-center">
              <img data-src='/img/home/logos/1-abc.png' className='img-logo my-3 mx-2 abc lazyload' alt="as-seen-on-abc" />
              <img data-src='/img/home/logos/2-cbs.png' className='img-logo my-3 mx-2 cbs lazyload' alt="as-seen-on-cbs" />
              <img data-src='/img/home/logos/3-fox.png' className='img-logo my-3 mx-2 fox lazyload' alt="as-seen-on-fox" />
              <img data-src='/img/home/logos/4-nbc.png' className='img-logo my-3 mx-2 nbc lazyload' alt="as-seen-on-nbc" />
              <img data-src='/img/home/logos/5-today.png' className='img-logo my-3 mx-2 today lazyload' alt="as-seen-on-today" />
              <img data-src='/img/home/logos/6-tuck.png' className='img-logo my-3 mx-2 tuck lazyload' alt="as-seen-on-tuck"/>
              <img data-src='/img/home/logos/7-leafly.png' className='img-logo my-3 mx-2 leafly lazyload' alt="as-seen-on-leafly" />
              <img data-src='/img/home/logos/8-buzzfeed.png' className='img-logo my-3 mx-2 buzzfeed lazyload' alt="as-seen-on-buzzfeed" />
              <img data-src='/img/home/logos/9-forbes.png' className='img-logo my-3 mx-2 forbes lazyload' alt="as-seen-on-forbes" />
              <img data-src='/img/home/logos/91-us.png' className='img-logo my-3 mx-2 us lazyload' alt="as-seen-on-us" />          
            </div>
          </div>
        </section>
        {/* End Section As Seen On */}
      </Layout>
    )
  }
}

export default RootIndex

export const homeQuery = graphql`
  query RootIndexQuery {
    site {
      siteMetadata {
        title
        siteUrl
        siteUrlEs
      }
    }
    homepageConfig: contentfulHomePage(title: {eq: "Homepage EN"}) {
      reviews {
        ...contentfulReview
      }
    }
  }
`
